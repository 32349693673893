<template>
  <div>
    <SearchBar/>

    <v-container>
      <v-row>
        <v-col>
          <withGet 
              :url="`${baseUrl}objectcounts/`"
              v-slot="{items:counts}"
            >
            <table>
              <tr>
                <th class="text-left">Path</th>
                <th class="text-right">Object Count</th>
              </tr>
              <template v-for="count, path in counts">
                <tr>
                  <td class="text-left">{{ path }}</td>
                  <td class="text-right">{{ count }}</td>
                </tr>
            </template>
            </table>
            </withGet>
        </v-col>
        <v-col>
          <h2>Objects Missing Service ID</h2>
          
        <withSchemas v-slot="{ schemas }">
          <!-- {{ JSON.stringify(schemas, null, 2) }} -->
          <div v-for="path in servicePaths(schemas)" >
            <withGet 
              :url="`${baseUrl}${path}/?service_auto_id=0`"
              v-slot="{items}"
            >
            <v-expansion-panels class="mt-2">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ path }} [ {{ Object.values(items).length }} ]
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <template v-for="line in Object.values(items)">
                    <v-btn variant="outline" :href="`/v2/${path}/${line.id}`" target="_blank" block class="mb-2 text-left" 
                      :title="`Open ${path}:  #${line.id}`">
                      <div class="text-uppercase mr-4" style="width: 25%">
                        {{ path }}
                      </div>
                      {{  line.repr }}
                      <div class="w-4 mx-2 ml-auto">
                        #{{ line.id }}
                      </div>
                      <v-icon right class="">mdi-open-in-new</v-icon>
                    </v-btn>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            </withGet>
          </div>
        </withSchemas>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import withSchemas from "@/components/Core/withSchemas.vue";
import withGet from "@/components/Core/withGet.vue";
import SearchBar from "@/components/SearchBar.vue";
export default {
  components: { SearchBar, withSchemas, withGet },
  data() {
    return {
      loading: false,
      baseUrl: process.env.VUE_APP_PRESTO_CORE_API || false
    }
  },
  methods: {
    servicePaths(schemas) {
      return Object.keys(schemas).filter(key => schemas[key].properties?.service_auto_id);
    }
  }
}
</script>
